import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Logo } from 'kdc-component-library';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useMsal } from '@azure/msal-react';
import Sidebar from '../Sidebar';
import axios from '../../services/Api';
import CreatePlanModal from '../CreatePlanModal';
import UnauthorizedModal from '../UnauthorizedModal';
import { PlanType } from '../../enums/PlanType';
import AccessEnum from '../../enums/Access';
import CreateEditViewEnum from '../../enums/CreateEditView';

import '../../styles/components/pages/LandingPage.scss';

/**
 * LandingPage component
 * @returns {JSX} JSX to render the component
 */
export const LandingPage = (props) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [planUser, setPlanUser] = useState({});
  const [plans, setPlans] = useState([]);
  const [allLocations, setLocations] = useState([]);
  const [businessTeamsList, setBusinessTeamsList] = useState([]);
  const [createEditViewAuthority, setCev] = useState(false);
  const [selected, setSelected] = useState('');
  const [landingSidebar, setLandingSidebar] = useState(false);
  const [createPlanModalOpen, setCreatePlanModalOpen] = useState(false);
  const [unauthorizedModalOpen, setUnauthorizedModalOpen] = useState(false);
  const { renderSidebar, dispatch } = props; // redux state
  const { t } = useTranslation('common');
  const { instance } = useMsal();

  // styles for dropdown
  const customStyles = {
    control: (styles) => ({
      ...styles,
      fontWeight: '650',
      backgroundColor: 'rgb(230, 230, 230)',
      color: 'rgb(68, 68, 68)',
      border: 'solid 1px black',
      lineHeight: 'normal',
      height: '40px',
      width: '400px',
      textAlign: 'center',
      textAlignLast: 'center',
      cursor: 'pointer',
      appearance: 'none',
      boxShadow: '0 !important',
      '&:hover': {
        border: 'solid 1px black !important',
      },
    }),
  };

  const sidebarClose = () => {
    dispatch({ type: 'SIDEBAR' });
  };

  // Default landingPage items
  const items = [
    {
      id: t('selectMyPlan'),
      onClick: () => {
        history.push('/');
        sidebarClose();
      },
      label: t('selectMyPlan'),
      className: 'top-item',
    },
    {
      id: t('runAReport'),
      onClick: () => {
        history.push('/report');
        sidebarClose();
      },
      label: t('runAReport'),
      className: 'top-item',
    },
    {
      id: t('support'),
      onClick: () => {
        history.push('/support');
        sidebarClose();
      },
      label: t('support'),
      className: 'landing-item',
    },
    {
      id: 'Home',
      label: t('home'),
      right: true,
      url: '/',
      className: 'landing-item',
    },
    {
      id: 'Logout',
      label: t('logout'),
      right: true,
      onClick: () => instance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL }),
      className: 'landing-item',
    },
  ];

  /** Retrieves user's plans */
  useEffect(() => {
    const abortController = new AbortController();

    const getData = async () => {
      try {
        const { data: user } = await axios.get('/user');
        const { data: createEditViewAuth } = await axios.get(
          `/auth/CEV/${user._id}`, { signal: abortController.signal },
        );
        const [{ data: plansList }, { data: list }, { data: locations }] = await Promise.all([
          createEditViewAuth ? axios.get('/plan', { signal: abortController.signal }) : { data: [] },
          user.isAdmin ? axios.get('/admin/users/businessTeamList', { signal: abortController.signal }) : { data: [] },
          axios.get('/constants/locations', { signal: abortController.signal }),
        ]);

        setPlans(plansList);
        setUserName(user.firstName);
        setUserRoles(user.role);
        setPlanUser(user);
        setCev(createEditViewAuth);
        setLocations(locations);
        setBusinessTeamsList(list);
        setLoading(false);
      } catch (err) {
        if (err.name === 'CanceledError') return;
        if (err.message.includes('401')) {
          // The Api service will redirect to the unauthorized page on repeated 401's
          return;
        }
        setCev(false);
        setLoading(false);
      }
    };

    getData();
    setLandingSidebar(true);
    return () => {
      abortController.abort();
    };
  }, []);

  const editPlan = () => {
    if (selected) {
      const planObj = plans.find((plan) => plan._id === selected); // finds plan w/ selected id
      history.push({
        pathname: '/edit-plan',
        search: `planId=${selected}&sectionKey=${planObj.datakey ? planObj.datakey : 'businessImpactAnalysis'}`,
      });
    }
  };

  const viewPlan = () => {
    if (selected) {
      history.push({
        pathname: '/view-plan',
        search: `?planId=${selected}`,
      });
    }
  };

  const reportPage = () => {
    history.push('/report');
  };

  /**
   * Gets the plan type depending on the role (assuming 1 role)
   * If user does not have a recognized role, then no template is assigned
   */
  const getPlanType = () => {
    const templates = [t('bccPlan'), t('btPlan'), t('logisticsPlan')];
    const template = document.querySelector('input[name="template"]:checked')?.value;
    if (template === templates[0]) {
      return PlanType.BCC;
    }
    if (template === templates[1]) {
      return PlanType.BCP;
    }
    if (template === templates[2]) {
      return PlanType.Logistics;
    }
    return PlanType.None;
  };

  /**
   * Created plan template and opens create modal
   */
  const createPlan = async (team, location, name) => {
    const planName = `${name} Plan`;
    const planType = getPlanType();
    const planTemplate = await axios.get('/plan-template', {
      params: {
        type: planType,
      },
    });
    const planId = planTemplate.data._id;
    const res = await axios.post('/admin/plan', {
      name: planName, templateId: planId, type: planType, location, team,
    });
    if (res.status === 201) {
      history.push({
        pathname: '/edit-plan',
        search: `?planId=${res.data._id}&sectionKey=${res.data.layout.sections[0].dataKey}`,
      });
    }
  };

  /**
   * Some roles just have create edit view buttons disabled instead of modal
   */
  const disableCreateEditView = () => !userRoles.find(
    (role) => CreateEditViewEnum.includes(role.name),
  ) && !planUser.isAdmin;

  /**
   * Some roles have no access to app, show NoAccessModal instead.
   */
  const noAccess = () => !userRoles.find(
    (role) => AccessEnum.includes(role.name),
  );

  /**
    * Sets the plan array to be used for the dropdown
    * @param {*} allPlans
    * @returns options array for the dropdown
  */
  const options = (allPlans) => {
    const plansArray = [];

    if (allPlans) {
      allPlans.forEach((planName) => {
        plansArray.push({ value: planName.name, label: planName.name, id: planName._id });
      });
    }
    return plansArray;
  };

  return (
    <div className="landingPage">
      {renderSidebar && landingSidebar && (
        <Sidebar
          closeSidebar={() => {
            sidebarClose();
          }}
          items={items}
        />
      )}
      <div className="landing-buttons">
        {!userRoles || isLoading || noAccess() ? (
          <Logo type="KDCSpinningLogo" />
        ) : (
          <>
            <span id="welcome-text">
              {t('welcomeMessage', { name: userName })}
            </span>
            <span id="landing-text">{t('landingText')}</span>
            <div>
              <Select
                classNamePrefix="dropdown"
                id="select-plan"
                onChange={(e) => { setSelected(e.id); }}
                options={options(plans)}
                placeholder={t('selectMyPlan')}
                styles={customStyles}
              />
            </div>
            <button
              type="button"
              id="view-plan"
              onClick={
                createEditViewAuthority
                  ? viewPlan
                  : () => setUnauthorizedModalOpen(true)
              }
              disabled={!createEditViewAuthority && disableCreateEditView()}
            >
              <div className="subtitled-button">
                <span className="button-title">{t('viewAPlan')}</span>
                <span className="button-subtitle">
                  {t('viewAPlanSubtitle')}
                </span>
              </div>
            </button>
            <button
              type="button"
              id="edit-plan"
              onClick={
                createEditViewAuthority
                  ? editPlan
                  : () => setUnauthorizedModalOpen(true)
              }
              disabled={!createEditViewAuthority && disableCreateEditView()}
            >
              {t('editMyPlan')}
            </button>
            <button
              type="button"
              id="create-report"
              onClick={reportPage}
            >
              {t('runAReport')}
            </button>
            { planUser.isAdmin && (
            <button
              type="button"
              id="create-plan"
              onClick={
                createEditViewAuthority
                  ? () => setCreatePlanModalOpen(true)
                  : () => setUnauthorizedModalOpen(true)
              }
              disabled={!createEditViewAuthority && disableCreateEditView()}
            >
              {t('createMyPlan')}
            </button>
            )}
          </>
        )}
      </div>
      <CreatePlanModal
        isOpen={createPlanModalOpen}
        createPlan={createPlan}
        location={allLocations}
        teamNames={businessTeamsList}
        handleClose={() => {
          setCreatePlanModalOpen(false);
        }}
      />
      <UnauthorizedModal
        id="unauthModal"
        isOpen={unauthorizedModalOpen}
        handleClose={() => {
          setUnauthorizedModalOpen(false);
        }}
        text="ok"
      />
      { userRoles && !isLoading && (
      <UnauthorizedModal
        id="noAccessModal"
        isOpen={noAccess()}
        handleClose={() => instance.logoutRedirect({
          postLogoutRedirectUri: window.AZURE_REDIRECT_URL,
        })}
        text="logout"
      />
      )}
    </div>
  );
};

LandingPage.defaultProps = {
  renderSidebar: false,
  dispatch: null,
};

LandingPage.propTypes = {
  renderSidebar: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps)(LandingPage);
