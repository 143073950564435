import React, { useState } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import PageContainer from '../components/PageContainer';
import LandingPage from '../components/pages/LandingPage';
import SupportPage from '../components/pages/SupportPage';
import EditPlanPage from '../components/pages/EditPlanPage';
import ViewPlanPage from '../components/pages/ViewPlanPage';
import ReviewPlanPage from '../components/pages/ReviewPlanPage';
import ReportPage from '../components/pages/ReportPage';
import UnauthorizedPage from '../components/pages/UnauthorizedPage';
import AutoLogout from '../services/AutoLogout';
import history from '../history';

/**
 * Router wrapped with CacheBuster to refresh and reload
 * @returns {*} JSX
 */
const AppRouter = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const [saveState, setSaveState] = useState({ _id: null });
  const { instance } = useMsal();

  /** List of items that will appear in the page's Navbar */
  const navItems = [
    {
      id: t('support'),
      url: '/support',
      label: t('support'),
    },
    {
      id: i18n.language === 'fr' ? 'FR' : 'EN',
      label: i18n.language === 'fr' ? 'french' : 'english',
      onClick: () => {
        if (i18n.language === 'en') {
          i18n.changeLanguage('fr');
        } else {
          i18n.changeLanguage('en');
        }
      },
    },
    {
      id: t('logout'),
      label: t('logout'),
      right: true,
      onClick: () => instance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL }),
    },
  ];

  return (

    <>
      <AutoLogout saveState={saveState} />
      <Router history={history}>
        <PageContainer
          navItems={navItems}
        >
          <Switch>
            <Route path="/" component={LandingPage} exact />
            <Route path="/report" component={ReportPage} exact />
            <Route path="/edit-plan" render={() => <EditPlanPage setSaveState={setSaveState} />} exact />
            <Route path="/view-plan" component={ViewPlanPage} exact />
            <Route path="/review-plan" component={ReviewPlanPage} exact />
            <Route path="/support" component={SupportPage} exact />
            <Route path="/edit" component={EditPlanPage} exact />
            <Route path="/unauthorized" exact component={UnauthorizedPage} />
          </Switch>
        </PageContainer>
      </Router>
    </>
  );
};
export default AppRouter;
